.revenue_circle_container {
  height: 50vh;
  border-radius: 10;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 6px -4px;
}

.revenue_circle {
  height: 45vh;
  display: "flex";
  border-radius: 10;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* Responsive styles */
@media (max-width: 575px) {
  .revenue_circle {
    height: 25vh;
    border-radius: 10;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .revenue_circle_container {
    height: 30vh;
    justify-content: "center";
    display: "flex";
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .revenue_circle {
    height: 25vh;
    border-radius: 10;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .revenue_circle_container {
    height: 30vh;
    justify-content: "center";
    display: "flex";
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .revenue_circle {
    height: 50vh;
    border-radius: 10;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

/* @media (min-width: 1024px) and (max-width: 1439px) {
  .revenue_circle {
    height: 50vh;
    border-radius: 10;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

@media (min-width: 1440px) {
  .revenue_circle {
    height: 50vh;
    border-radius: 10;
    background-color: white;
    justify-content: center;
    align-items: center;
    display: flex;
  }
} */
