.container {
  height: 100dvh;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  /* width: 10px; */
  /* height: 10px; */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.remarkBox textarea{
  background-color: #fff;
  border: 1px solid #e6ecf4;
 border-radius: 8px;
  height: 60px;
  padding: 0 0.875rem;
}
