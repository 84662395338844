.loader2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.loader2 .circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4caf50; /* Green color for the payment theme */
  animation: bounce 1.5s infinite ease-in-out;
}

.loader2 .circle:nth-child(2) {
  animation-delay: 0.2s;
}

.loader2 .circle:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
