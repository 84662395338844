.loanEMICalculator .modal-header {
  display: none;
}

.loanEMICalculator .modal-content {
  max-width: 600px;
}

.loanEMICalculator .close-button {
  position: absolute;
  right: 0;
  top: 0;
}

.loan-calculator-container input[type="number"] {
  width: 70% !important;
}
