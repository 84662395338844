.register-form {
  padding: 16px;
}

.tab-header {
  padding: 1rem 2rem 0.5rem;
  display: flex;
  list-style: none;
  margin: 0;
  /* background-color: #d9d9d9; */
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.tab-item {
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  background-color: #fff;
  line-height: 1;
  width: 100%;
  border: 1px solid #00a068;
  border-radius: 50px;
  position: relative;
  height: 40px;
  /* box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.4); */
}

.tab-itemHome {
  /* padding: 0;
  border: 0;
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0; */
  border-right: 1px dashed #00a068;
  color: #00a068;
}

.tab-itemHome a {
  /* width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; */
  /* padding-right: 2rem; */
  /* gap: 1rem;
  border-right: 1px dashed #00a068;
  color: #00a068; */
}
.tab-itemHome:hover a {
  /* background-color: #00a068; */
  /* border: 1px solid #00a068; */
  /* border-radius: 50px;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.9),
    0 0px 11px 0 rgba(0, 0, 0, 0.19);
  color: #fff; */
  font-weight: 600;
  color: #00a068;
}

.tab-itemHome:hover a svg {
  fill: #00a068;
}

.tab-itemHome:hover a svg .home-1 {
  fill: #00a068 !important;
}

.tab-itemHome:hover svg {
  border-width: 2px;
  border-color: #00a068 !important;
}

.tab-itemHome:hover svg .cls-1 {
  fill: #00a068;
}

.tab-item:last-child {
  /* border-top-right-radius: 25px;
  border-bottom-right-radius: 25px; */
}

.tab-item span {
  /* width: 50px;
  height: 40px;
  background-color: #000;
  margin-right: 2rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0 0.5rem; */
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  /* padding-left: 4rem; */
}
.tab-itemHome img {
  /* border-radius: 50%;
  height: 85px; */
}

.tab-itemHome svg {
  width: 35px;
  height: 35px;
  border-color: #00a068 !important;
}

.tab-itemHome span {
  padding-left: 2rem;
}

.tab-item img {
  position: absolute;
  left: 2rem;
}

.tab-item svg {
  border: 1px solid #00a068;
  border-radius: 50%;
  padding: 0.2rem;
  position: absolute;
  left: 0.9rem;
  width: 30px;
  height: 30px;
}

.tab-item.active svg {
  border: 2px solid #fff;
  border-radius: 50%;
  padding: 0.2rem;
  position: absolute;
  left: 0.5rem;
  width: 30px;
  height: 30px;
}

.tab-item.active svg .cls-1 {
  fill: #fff;
}

.tab-item.active {
  font-weight: 500;
  color: #fff;
  background-color: #00a068;
  box-shadow: 0px 3px 7px 0px rgba(171, 171, 171, 0.9),
    0 0px 11px 0 rgba(0, 0, 0, 0.19);
  /* border-bottom: 2px solid #cbbd6e; */
}

.tab-item.active span {
  /* width: 150px;
  justify-content: end; */
  /* box-shadow: 7px 1px 8px -4px rgba(255, 255, 255, 0.9),
    0 0px 18px 0 rgba(0, 0, 0, 0.19); */
  font-weight: 600;
}

.tab-contentWrapper {
  border: 1px solid #ccc;
  background-color: #f1f5f9;
  animation: fadeIn 0.4s ease;
  @apply shadow-customShadow relative w-full rounded-xl;
}

.tab-content {
  padding: 1rem 1.5rem 2rem;
}

.tab-content .button-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* position: absolute; */
  bottom: 0;
  /* padding: 2rem 0 0; */
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  /* padding-top: 2rem; */
}

.backBtn {
  background-color: #e4e4e4; /* Gold */
  color: #333;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.3s ease;
  border: 1px solid #b9b9b9;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.4),
    0 0px 11px 0 rgba(0, 0, 0, 0.19);
  line-height: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backBtn:hover:enabled {
  background-color: #5a6268; /* Darker Gray */
  border-color: #5a6268;
  color: #f1f5f9;
}

.nextBtn,
.submitBtn {
  background-color: #00a068; /* Gold */
  color: #fff;
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.3s ease;
  border: 1px solid #fff;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.4),
    0 0px 2px 0 rgba(0, 0, 0, 0.19);
  line-height: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nextBtn:hover,
.submitBtn:hover {
  background-color: #00a068; /* Darker Gold */
  border-color: #00a068;
}

.nextBtn:hover:enabled,
.submitBtn:hover:enabled {
  background-color: #00a068; /* Darker Gold */
  border-color: #00a068;
}

.customBtn {
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 0.875rem;
  transition: all 0.3s ease;
  /* border: 1px solid #fff; */
  /* box-shadow: 0px 3px 13px 0px rgba(0, 0, 0, 0.4),
    0 0px 11px 0 rgba(0, 0, 0, 0.19); */
  line-height: 1;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 200px;
  /* margin-top: 2.3px; */
  color: #fff;
  background-color: #00a068;
  border: 1px solid #089162;
}

.customBtn:hover:enabled {
  background-color: #016d47; /* Darker Gold */
  border-color: #016d47;
  color: #fff;
}

.registerSection input:disabled {
  background-color: #f3f3f3 !important;
  color: #666;
  cursor: not-allowed;
  border-color: #ccc !important;
  box-shadow: none !important;
}

button:disabled {
  background-color: #d1d5db;
  color: #9ca3af;
  cursor: not-allowed;
  border-color: #9ca3af;
}

button:disabled:hover {
  background: #d1d5db;
}

.editBtn {
  padding: 10px 20px;
  background-color: #ffcc00; /* Different color for edit button */
  border: none;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.editBtn:hover {
  background-color: #e6b800; /* Darker on hover */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.registerSection .tab-content label {
  font-weight: 400;
  color: #000;
  font-size: 14px;
  margin-bottom: 0.5rem;
  display: flex;
}

.registerSection .tab-content input[type="text"],
.registerSection .tab-content input[type="number"],
.registerSection .tab-content input[type="email"],
.registerSection .tab-content input[type="password"],
.occu_RightSide input[type="select"] {
  /* background: rgba(255, 255, 255, 0.5); */
  background-color: #fff;
  border: 1px solid #e6ecf4;
  /* box-shadow: 0px 0px 1px 0px #00000033; */
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding: 0 0.875rem;
  /* padding-right: 5rem; */
}

.radio-custom {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid #ccc;
  margin-right: 10px;
  transition: all 0.3s ease;
  display: flex;
}

.radio-input:checked + .radio-custom {
  border-color: #00650e;
  background-color: #3c8364;
}

.confirmation h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #00a068;
  text-transform: capitalize;
  line-height: 1;
}

.confirmation h4 {
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 3px;
  margin: 0.5rem 0;
  text-transform: uppercase;
}

.personalInfo .dropdown {
  width: 100% !important;
}

.personalInfo .dropdown-header {
  font-size: 0.8rem;
  height: 38px;
  padding-left: 14px;
  background-color: #fff;
}

.personalInfo .dropdown-header svg {
  width: 26px;
}
.personalInfo .dropdown .dropdown-option {
  font-size: 0.8rem;
}

input::placeholder {
  font-size: 0.875rem;
}

/* Responsive for tablet and mobile view */
@media (min-width: 992px) and (max-width: 1024.98px) {
  .registerSection .tab-content label {
    font-size: 0.9rem;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .registerSection .tab-content label {
    font-size: 0.8rem;
  }

  form {
    width: 100%;
  }

  .personalInfo .dropdown-header {
    font-size: 10px;
    padding-left: 11px;
  }

  .personalInfo .dropdown-header svg {
    width: 22px;
  }
}

@media (max-width: 1024px) {
  .tab-header {
    display: flex;
    background-color: transparent;
    /* margin-bottom: 20px; */
    flex-direction: column;
  }

  .tab-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-color: #f5f5f5;
    margin-bottom: 10px;
    /* border-radius: 8px; */
    transition: background-color 0.3s ease;
  }

  .tab-item.active {
    background-color: #00a068;
  }

  .tab-item span {
    height: 50px;
    padding-left: 3rem;
  }

  .tab-itemHome span {
    padding-left: 0 !important;
  }

  .tab-itemHome a {
    border-right: 0;
    border-bottom: 1px dashed #00a068;
    padding-left: 4rem !important;
    justify-content: flex-start;
  }

  .button-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
  }

  .backBtn,
  .nextBtn,
  .submitBtn {
    padding: 10px 20px;
    width: auto;
    margin-bottom: 10px;
  }
}

@media (max-width: 640px) {
  .tab-header {
    flex-direction: column;
  }
  .registerSection .tab-content input[type="text"],
  .registerSection .tab-content input[type="number"],
  .registerSection .tab-content input[type="email"],
  .registerSection .tab-content input[type="password"] {
    margin-bottom: 0 !important;
  }

  .registerSection .tab-content label {
    display: inline-block;
  }
  form {
    width: 100%;
  }
}

.occupationInfo form {
  width: 100%;
}

.registerSection .tab-contentWrapper {
  background: url("../../assets/BG-light.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}

.occu_RightSide .occu_RightSide-content {
  background: url("../../assets//logoNew-light.png");
  background-size: 100%;
  background-repeat: no-repeat;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  height: 100%;
  min-height: 300px;
}

.bgImg {
  background: url("../../assets//logoNew-light.png");
  background-size: 30%;
  background-repeat: no-repeat;
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  height: 100%;
  min-height: 300px;
}

/* responsive css start */

@media (max-width: 1024.98px) {
  .registerSection {
    height: 100%;
    padding: 0;
  }
  .tab-itemHome {
    border-right: 0;
    border-bottom: 1px dashed #00a068;
    padding-bottom: 1rem;
  }
  .tab-itemHome img {
    padding-right: 0;
  }
}
