.dashboard {
  margin-left: 0;
  margin-top: 60px;
}

.grid_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 10px;
  padding: 10px;
  transition: margin-left 0.3s ease;
}

.TurnoverYearly,
.CrossSell,
.Revenue,
.NewLoans {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;

  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Responsive styles */
@media (max-width: 575px) {
  .grid_container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, auto);
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .grid_container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, auto);
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .grid_container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, auto);
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;
    padding: 10px;
    transition: margin-left 0.3s ease;
  }
}

@media (min-width: 1440px) {
  .grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 10px;
    padding: 10px;
    transition: margin-left 0.3s ease;
  }
}
