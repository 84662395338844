/* Main Content */
.main_content {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background-color: #000000;
  color: white;
  padding: 0 20px;
  z-index: 1000;
}

.navbar {
  background-color: #000000;
  /* padding: 0.5rem 2rem; */
  color: white;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  overflow: visible;
  height: 100%;
  gap: 1rem;
}

.menu_icon {
  font-size: 2rem;
  cursor: pointer;
  /* width: 3%; */
}

.navbar_title {
  font-size: 1.3rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  right: auto;
  transform: translateX(-50%);
}

.header_icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* width: 18%; */
}

.icon_circle {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* margin-left: 2rem; */
  cursor: pointer;
  font-size: 1.5rem;
  max-width: 1.5rem;
  max-height: 1.5rem;
  justify-content: center;
  align-items: center;
}

/* Dropdown menu styles */
.icon_circle .dropdown_menu {
  position: absolute;
  background-color: #3c8f47;
  width: 100px;
  top: 2rem;
  right: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon_circle .dropdown_menu h5 {
  margin: 0;
  padding: 10px;
  font-size: 0.8rem;
}

.buttons {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #3c8f47;
  color: white;
  cursor: pointer;
}

/* Dark Mode Global Styles */
.dark-mode {
  background-color: #1e1e1e;
  color: #ffffff;
}

.dark-mode .navbar {
  background-color: #333;
  color: #ffffff;
}

.dark-mode input,
.dark-mode select,
.dark-mode textarea {
  background-color: #444;
  color: #ffffff;
  border-color: #666;
}

/* responsive css start */
@media (min-width: 768px) and (max-width: 991.98px) {
  .navbar_title {
    font-size: 1.2rem;
  }
}
@media (max-width: 575.98px) {
  .navbar_title {
    font-size: 0.9rem;
  }
  .menu_icon,
  .navbar_title,
  .header_icons {
    width: auto;
  }
}
