/* Styles for the main menu */

header {
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
      color: #212529; */
  @apply bg-slate-900 w-full flex items-center lg:block sticky top-0 left-0 z-50 h-auto md:h-24 lg:h-auto lg:py-0 gap-4;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
  margin-right: 20px;
  color: #fff;
}

/* menu on desktop */
.desktop-nav {
  @apply bg-yellow-600 text-white lg:text-stone-900 lg:bg-gradient-to-t from-yellow-600 via-yellow-200 to-yellow-600
      border-b border-b-yellow-300 border-t-yellow-300 fixed lg:static top-0 w-72 lg:w-full h-full lg:h-auto
      transition-all ease-in-out duration-300 overflow-auto lg:overflow-visible;
}

.desktop-nav .menus {
  display: flex;
  align-items: center;
  list-style: none;
}

.desktop-nav .menu-items {
  position: relative;
  font-size: 14px;
}

.desktop-nav .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.desktop-nav button span {
  margin-left: 3px;
  width: auto;
  height: auto;
}

.desktop-nav .menu-items > a,
.desktop-nav .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.desktop-nav .menu-items a:hover,
.desktop-nav .menu-items button:hover {
  background-color: #f2f2f2;
}

.desktop-nav .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.desktop-nav .dropdown {
  position: absolute;
  right: auto;
  left: 0;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
  min-width: unset;
  width: 22rem;
}

.desktop-nav .dropdown.show {
  /* display: block; */
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: 0;
  width: 100%;
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.mobile-nav {
  display: none;
}

/* menu on mobile */

#error-page {
  /* center content on the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}

.burger {
  @apply w-6 h-6 absolute cursor-pointer block lg:hidden -top-5;
  /* top-[50%] -translate-y-[50%] */
}

.active.burger {
  @apply -top-5;
}
.burger span {
  @apply w-full h-[2px] bg-white absolute left-0 top-[50%] transition-all duration-200;
}
.burger.active span {
  @apply bg-white;
}
.burger span:first-child {
  @apply top-[20%];
}
.burger span:last-child {
  @apply top-[80%];
}
.burger.active span:nth-child(2) {
  @apply scale-0;
}
.burger.active span:first-child {
  transform: rotate(-45deg);
  transform-origin: center;
  top: 50%;
}
.burger.active span:last-child {
  transform: rotate(45deg);
  transform-origin: center;
  top: 50%;
}

button.mobile-nav__close-button {
  right: 10px;
  display: flex;
  position: absolute;
  top: 10px;
  width: 30px;
  height: 30px;
  background-color: #ccc;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
}

@media screen and (max-width: 1024.98px) {
  .mobile-nav {
    display: block;
  }

  .mobile-nav .menus {
    list-style: none;
    position: absolute;
    top: 85px;
    /* right: 20px; */
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: #fff;
    border-radius: 0.5rem;
    width: 95%;
    margin: 0 auto;
    left: 0;
    right: 0;
    overflow-y: auto;
    height: 85dvh;
  }

  .mobile-nav .menu-items > a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }

  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }
}

@media screen and (min-width: 961px) and (max-width: 1024.98px) {
  .burger {
    top: 2rem !important;
    right: 12px;
  }
}
@media screen and (max-width: 960.98px) {
  header {
    min-height: 6rem;
    align-items: flex-start;
    /* flex-wrap: wrap; */
  }

  .burger {
    right: -20px;
    top: -12px;
  }
  .active.burger {
    right: -20px;
    top: -12px;
  }

  .nav-area {
    /* justify-content: space-between; */
    /* width: 100%;
      display: flex;
      align-items: center;
      justify-content: end; */
    /* width: 100% !important; */
  }

  .desktop-nav {
    display: none;
    text-align: right;
  }

  .mobile-nav {
    display: block;
    padding-right: 1rem;
  }
  .mobile-nav .mobile-nav__menu-button {
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    color: #fff;
  }
  .mobile-nav .menus {
    list-style: none;
    position: absolute;
    top: 146px;
    /* right: 20px; */
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    z-index: 9999;
    min-width: 12rem;
    padding: 0.5rem 0;
    background-color: #fff;
    border-radius: 0.5rem;
    width: 95%;
    margin: 0 auto;
    left: 0;
    right: 0;
    overflow-y: auto;
    height: 85dvh;
  }

  .mobile-nav .menu-items a {
    display: block;
    font-size: inherit;
    color: inherit;
    text-decoration: none;
  }

  .mobile-nav .menu-items button {
    display: flex;
    align-items: center;
    color: inherit;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    width: 100%;
  }

  .mobile-nav .menu-items > a,
  .mobile-nav .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
  }

  .mobile-nav .menu-items a:hover,
  .mobile-nav .menu-items button:hover {
    background-color: #f2f2f2;
  }

  .mobile-nav .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }
  .mobile-nav .arrow-close::after {
    content: "";
    display: inline-block;
    margin-left: 1.2em;
    vertical-align: 0.09em;
    border-bottom: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .mobile-nav .dropdown {
    margin-left: 1.2em;
    font-size: 0.9rem;
    padding: 0.5rem 0;
    list-style: none;
    display: none;
  }

  .mobile-nav .dropdown.show {
    display: block;
  }
}
@media (min-width: 640) and (max-width: 700.98px) {
  header {
    height: 6rem;
  }
}
@media (min-width: 576px) and (max-width: 640.98px) {
  header {
    height: 10rem;
  }
}

@media (max-width: 575.98px) {
  header {
    min-height: 185px;
    /* gap: 0; */
    flex-wrap: wrap;
  }

  .nav-area {
    width: 100%;
  }

  .burger {
    top: -50px;
    right: -10px;
    /* transform: translateY(-50%); */
  }
  .mobile-nav .menus {
    top: 170px;
  }
  .active.burger {
    z-index: 9;
    /* top: -20px;
    right: -24px; */
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
  }
  .burger.active span {
    /* background-color: #333; */
    z-index: 9;
  }

  .payEmi_Btn {
    width: 120px;
    text-align: center;
    font-size: 0.7rem;
  }
  .rightHeader_box {
    justify-content: center;
  }
}

@media (max-width: 450.98px) {
  .header {
    gap: 1rem;
  }
  .glassEffect {
    padding: 3rem;
  }
  .rightHeader_box {
    justify-content: center;
    gap: 0.5rem;
  }
  .rightHeader_box .btn-green {
    /* width: 70%; */
  }

  .cust-container {
    padding: 0 0.5rem;
  }

  .nav-area {
    width: 100%;
  }

  .burger {
    top: 3px;
  }
}
