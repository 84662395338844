.container {
  overflow-y: auto;
  height: calc(100vh - 100px);
}

.table_container1 {
  margin-top: 70px;
}
.table_container_title {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.table_container_borrower {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  border: 2px solid #133b19;
  margin: 20px auto;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  padding: 5px;
}

.table_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  border: 1px solid #133b19;
  margin: 20px auto;
  color: #ffffff;
}

.table_header {
  display: flex;
  background-color: #133b19;
  padding: 5px;
}

.header_item {
  flex: 1;
  padding: 10px;
  color: #fff;
  text-align: center;
}

.no_item {
  flex: 1;
  padding: 10px;
  color: #000;
  text-align: center;
}

.table_row {
  display: flex;
  border-top: 1px solid #ddd;
}

.table_cell {
  flex: 1;
  padding: 10px;
  text-align: center;
  border-right: 1px solid #ddd;
  color: black;
}

.table_cell:last-child {
  border-right: none;
}

select {
  margin: 20px;
  padding: 10px;
  font-size: 16px;
}

.checkbox {
  position: relative;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 4px;
}

.checkbox.checked::after {
  content: "✓";
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
}

.submit_button {
  margin-top: "20px";
  padding: "10px 20px";
  color: "#fff";
  border: "none";
  border-radius: "5px";
  cursor: "pointer";
}

.agree_container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 20px auto;
  font-size: 16px;
  color: #333;
  align-items: center;
}

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.agree_text {
  font-size: 16px;
  color: #333;
}

/* 
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
} */

.modal_content {
  width: 100%;
  max-width: 800px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* text-align: center; */
}

.grid_container {
  display: grid;
  padding-left: 20px;
  padding-right: 20px;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; /* Space between grid items */
  margin: 10px 0; /* Space above and below the grid */
}

.grid_item {
  background: #fff;
  padding: 10px;
  color: #000;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

button {
  margin-top: 10px;
}

.modal_header {
  display: flex;
  background-color: #133b19;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* border-top-left-radius: 4px;
  border-top-right-radius: 4px; */
  justify-content: space-between;
  align-items: center;
}

.close_button {
  background: none;
  border: none;
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
  color: #fff; /* Adjust the color as needed */
}

/* .search_container {
  margin-bottom: 20px;
} */

.search_input {
  display: flex;
  max-width: 800px;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #133b19;
  margin: 20px auto;
}
