/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

@layer base {
  body {
    @apply text-stone-900 font-sans;
    margin: 0;
    background-color: #f0f2f5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  ul li,
  p {
    @apply text-sm;
  }
  :focus-visible {
    outline: 0;
  }
  /* :focus {
    box-shadow: none !important;
  } */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.legalContent ul {
  @apply pl-4 my-2 ml-1;
}
.legalContent ul li {
  @apply text-gray-600 text-sm;
}
.legalContent p {
  @apply text-gray-600 text-sm;
}

.btn-gold {
  @apply bg-gradient-to-t from-yellow-600 via-yellow-200 to-yellow-600 relative overflow-hidden;
}
.btn-gold:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.btn-gold:hover:after {
  width: 120%;
  background-color: rgba(255, 255, 255, 0);

  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.btn-dark {
  @apply bg-slate-800 text-white relative overflow-hidden;
}
.btn-dark:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.btn-dark:hover:after {
  width: 120%;
  background-color: rgba(255, 255, 255, 0);

  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.flipBox_inner {
  transform-style: preserve-3d;
  transition: transform 0.7s ease-in-out;
}

.flipBox:hover .flipBox_inner,
.flipBox.flipped .flipBox_inner {
  transform: rotateY(180deg);
}

.flipBox_front,
.flipBox_back {
  backface-visibility: hidden;
}

.flipBox_front {
  transform: rotateY(0deg);
}

.flipBox_back {
  transform: rotateY(180deg);
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="date"],
input[type="number"] {
  font-size: 0.875rem;
}
select,
select option {
  font-size: 0.875rem;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* For Firefox */
}

.payEmi_Btn {
  width: 120px;
  text-align: center;
  font-size: 0.7rem;
}

.rupees {
  border-left: 1px solid #e5e7eb;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-stone-900 font-sans;
  }
  ul li,
  p {
    @apply text-sm;
  }
  :focus-visible {
    outline: 0;
  }
  /* :focus {
    box-shadow: none !important;
  } */
}
@layer utilities {
  .border-gradient-to-t {
    border-width: 4px; /* Adjust the border width as needed */
    border-style: solid;
    border-image: linear-gradient(
      to top,
      #ffeb3b,
      #ffc107,
      #ffeb3b
    ); /* Customize the gradient colors */
  }
}
@layer components {
  .innerGlassEffect {
    /* Base styles */
    @apply border-2 border-green-900 p-10 rounded-xl m-auto static md:absolute left-auto xl:right-64;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10.9px);
    -webkit-backdrop-filter: blur(10.9px);
    width: 400px;
    background: rgba(255, 255, 255, 0.1);
    position: absolute;
    left: auto;
    right: 24rem;
    top: 50%;
    transform: translateY(-50%);
  }

  /* Media query for screens larger than a certain breakpoint (e.g., min-width: 768px) */
  @screen xl {
    .innerGlassEffect {
      width: 450px; /* Adjust width for larger screens */
    }
  }

  /* Custom media query for a specific range */
  @media (min-width: 1201px) {
    .innerGlassEffect {
      @apply right-16;
    }
  }
  @media (min-width: 992px) and (max-width: 1200px) {
    .innerGlassEffect {
      display: block;
      @apply right-16;
      width: 300px;
      padding: 1.5rem;
    }
  }
  @media (max-width: 991px) {
    .innerGlassEffect {
      display: none !important;
    }
  }
}

@layer utilities {
  .banner-text {
    @apply border border-yellow-300 left-[auto] right-0 p-16 rounded-2xl;
  }
  .glassEffect {
    @apply border border-yellow-300 p-16 rounded-2xl m-auto;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(16.9px);
    -webkit-backdrop-filter: blur(16.9px);
    max-width: 700px;
    background: rgba(0, 160, 104, 0.06);
  }

  .slide-in {
    @apply absolute top-0 left-0 w-full h-screen bg-slate-100 origin-bottom;
  }
  .slide-out {
    @apply absolute top-0 left-0 w-full h-screen bg-slate-100 origin-top;
  }
  .text-shadow {
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.75);
  }
  .gold-gradient {
    @apply bg-gradient-to-t from-yellow-600 via-yellow-200 to-yellow-600;
  }
  .form-group {
    @apply relative;
  }
  .form-label {
    @apply mb-1 inline-block text-sm;
  }
  .form-control {
    @apply border w-full px-3 py-1.5 rounded-md block;
  }
  .btn {
    @apply px-3 py-1.5 rounded-md font-medium;
  }
  .btn-gold {
    @apply bg-yellow-600 bg-gradient-to-t from-yellow-600 via-yellow-200 to-yellow-600 relative overflow-hidden;
  }
  .btn-gold:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
  .btn-gold:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);

    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .btn-dark {
    @apply bg-slate-800 text-white relative overflow-hidden;
  }
  .btn-dark:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
  .btn-dark:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);

    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  .btn-secondary {
    @apply bg-yellow-700 bg-gradient-to-br from-yellow-700 via-yellow-300 to-yellow-800 relative overflow-hidden;
  }
  .btn-secondary:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
  .btn-secondary:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);

    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  .btnGreen {
    @apply bg-green-600 bg-gradient-to-t relative overflow-hidden text-base text-white shadow-shadow_1 rounded-lg hover:bg-green-900;
  }

  .btn-green {
    @apply bg-green-700 bg-gradient-to-t relative overflow-hidden text-sm text-gray-100 shadow-shadow_3 rounded-lg from-green-700 via-green-900 to-green-700 border-solid border-[1px] border-green-600;
  }

  .btn-green:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background-color: rgba(22, 163, 74, 0.4);
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
  .btn-green:hover:after {
    width: 120%;
    background-color: rgba(255, 255, 255, 0);

    -webkit-transition: all 0.4s ease-in-out;
    -moz-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  .footer-block h4 {
    @apply text-slate-400 mb-2;
  }
  .footer-panel {
    @apply text-white text-sm flex flex-col gap-1;
  }
  .hamburger {
    @apply w-8 h-5 relative flex lg:hidden flex-col items-center justify-between;
  }
  .hamburger span {
    @apply w-full h-[2px] bg-white rounded-full absolute duration-300 ease-in-out;
  }
  .hamburger span:first-child {
    transform-origin: center;
    top: 0;
  }
  .hamburger span:nth-child(2) {
    transform-origin: center;
    top: 50%;
    transform: translateY(-50%);
  }
  .hamburger span:last-child {
    transform-origin: center;
    bottom: 0;
  }
  .hamburger.active span:first-child {
    transform: rotate(45deg) translateY(-50%);
    transform-origin: center;
    top: 50%;
  }
  .hamburger.active span:nth-child(2) {
    transform-origin: center;
    width: 0;
  }
  .hamburger.active span:last-child {
    transform: rotate(-45deg) translateY(-50%);
    transform-origin: center;
    top: 50%;
  }

  .accordion-item {
    @apply mb-0 py-2 border-b border-white/20 last:border-0;
  }
  .accordion-title {
    @apply font-medium md:font-semibold !text-base md:!text-base lg:!text-sm text-slate-900 flex cursor-pointer relative w-full pr-7;
  }
  .accordion-content {
    @apply font-normal text-slate-900 text-sm md:pr-4 mt-3 pl-5 pr-5 pb-5;
  }

  .accordion_scroll::-webkit-scrollbar {
    @apply bg-gray-300 w-2 rounded-lg;
  }

  .accordion_scroll::-webkit-scrollbar-track {
    @apply bg-gray-300 rounded-lg;
    box-shadow: inset 0 0 6px rgb(197, 197, 197);
  }

  .accordion_scroll::-webkit-scrollbar-thumb {
    @apply bg-green-600 rounded-lg;
  }

  .accordion_scroll::-webkit-scrollbar-thumb:hover {
    @apply bg-green-600 rounded-lg;
  }
  .accordion-header {
    @apply px-4 py-2 shadow-md rounded-lg bg-slate-100;
  }
  .accordion-title {
    @apply uppercase;
  }
  .accordion-item ul li {
    @apply mb-2;
  }
  .loans-table {
    @apply min-w-full;
  }
  .loans-table th,
  .loans-table td {
    @apply pl-4 pr-4 p-2 border text-sm;
  }
  .loans-table th {
    @apply bg-green-600 text-white font-normal tracking-wide;
  }
  .loans-table tfoot,
  .loans-table tfoot tr,
  .loans-table tfoot tfoot th,
  .loans-table tfoot td {
    @apply border-0;
  }

  .footer_accordion-item {
    @apply mb-0 py-3 px-2 border-b border-white/20 last:border-0;
  }
  .footer_accordion-title {
    @apply font-medium md:font-semibold text-base flex cursor-pointer relative w-full pr-7 uppercase;
  }
  .footer_accordion-content {
    @apply font-normal text-slate-900 text-sm md:pr-4 mt-3 pl-5 pr-5;
  }

  .footer_accordion_scroll::-webkit-scrollbar {
    @apply bg-gray-300 w-2 rounded-lg;
  }

  .footer_accordion_scroll::-webkit-scrollbar-track {
    @apply bg-gray-300 rounded-lg;
    box-shadow: inset 0 0 6px rgb(197, 197, 197);
  }

  .footer_accordion_scroll::-webkit-scrollbar-thumb {
    @apply bg-green-600 rounded-lg;
  }

  .footer_accordion_scroll::-webkit-scrollbar-thumb:hover {
    @apply bg-green-600 rounded-lg;
  }
  .footer_accordion-header {
  }
  .footer_accordion-item ul li {
    @apply mb-2 text-white font-normal text-sm capitalize;
  }
  .footer_accordion-item ul li a {
    @apply hover:text-green-600;
  }
  .footer_accordion-item h5 {
    @apply mb-2 text-yellow-500 font-semibold text-sm;
  }

  /* flipBox css start */
  .flipBox {
    position: relative;
    perspective: 1000px;
  }

  .flipBox_inner {
    position: relative;
    /* width: 100%;
    height: 100%; */
    width: 100px;
    height: 100px;
    text-align: center;
    transition: transform 0.8s ease-in-out;
    transform-style: preserve-3d;
    color: #fff;
    text-align: center;
  }

  .flipBox:hover .flipBox_inner {
    transform: rotateY(180deg);
  }

  .flipBox_front,
  .flipBox_back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 1rem;
  }

  .flipBox_front {
    transform: rotateY(0deg);
  }

  .flipBox_back {
    transform: rotateY(180deg);
    padding: 10px;
  }
  .flipBox_inner.flipped {
    transform: rotateY(180deg);
  }
  /* flipBox css end */
}

/* input {
  background-color: #f1f1f1;
  border-radius: 6px !important;
  border: 1px solid #ccc;
} */

.close {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 24px;
  cursor: pointer;
}

.error {
  color: red;
}

.disabled-link {
  cursor: not-allowed;
}

.disabled-link:hover > div {
  box-shadow: none !important;
  background-color: #f1f5f9 !important;
}

input {
  background-color: #fff;
  border: 1px solid #e6ecf4;
  box-shadow: 0px 0px 1px 0px #00000033;
  height: 40px;
  width: 100%;
  padding: 0 0.875rem;
}

/* form box design css */
.formBoxDesign {
  @apply min-h-[430px] bg-[#f1f5f9] py-6 px-8 border border-gray-400 rounded-xl;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.9), 0 0px 23px 0 rgba(0, 0, 0, 0.19);
}

.formBoxDesign input[type="text"],
.formBoxDesign input[type="number"],
.formBoxDesign input[type="email"],
.formBoxDesign input[type="password"] {
  /* background: rgba(255, 255, 255, 0.5); */
  background-color: #fff;
  border: 1px solid #e6ecf4;
  box-shadow: 1px 1px 4px 0px #00000033;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding: 1rem;
}

.shadowLight {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1),
    0 0px 0px 0 rgba(0, 0, 0, 0.19);
}
.boxShadow {
  box-shadow: 1px 1px 4px 0px #434343e8;
}

/* upload document dropzone css */
.dropzone {
  min-height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainFormWrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

/* Style the slider thumb for WebKit browsers */
.custom-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none !important; /* Removes default thumb styling */
  appearance: none !important;
  width: 20px;
  height: 20px;
  background: #cc9703 !important; /* Thumb color */
  border-radius: 50%; /* Make thumb circular */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add shadow */
  cursor: pointer; /* Pointer cursor for thumb */
}

.innerHeader_Logo img {
  width: 100px;
}
.innerHeader_Login {
  padding: 0;
  background: transparent;
  /* width: 200px; */
  box-shadow: none;
  border: 0;
  border-radius: 0;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerHeader_Login:hover a {
  font-weight: 600;
  color: #00a068;
}

.innerHeader_Login:hover svg {
  border-width: 2px;
  border-color: #00a068 !important;
}

.innerHeader_Login:hover a svg {
  fill: #00a068;
}

.innerHeader_Login a {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  height: 100%;
  /* padding-right: 2rem; */
  gap: 1rem;
  color: #00a068;
}

.innerHeader_Login svg {
  border: 1px solid #00a068;
  border-radius: 50%;
  padding: 0.2rem;
  width: 30px;
  height: 30px;
}

.loginScreen input[type="email"],
.loginScreen input[type="password"],
.forgotScreen input[type="email"] {
  font-size: 0.875rem;
  background-color: #fff;
  border: 1px solid #e6ecf4;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  padding: 0 0.875rem;
  box-shadow: 0px 0px 1px 0px #00000033;
}

.custButton {
  @apply relative overflow-hidden;
}
.custButton:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  -webkit-transition: none;
  -moz-transition: none;
  transition: none;
}
.custButton:hover:after {
  width: 120%;
  background-color: rgba(255, 255, 255, 0);

  -webkit-transition: all 0.6s ease-in-out;
  -moz-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}

/* commom input css */
/* input[type="text"] {
  background-color: #fff;
  border: 1px solid #e6ecf4;
  border-radius: 8px;
  height: 40px;
  width: 100%;
  font-size: 0.875rem;
  box-shadow: 0px 0px 1px 0px #00000033;
  padding: 8px;
} */

.formContentWrapper {
  border: 1px solid #ccc;
  background-color: #f1f5f9;
  animation: fadeIn 0.4s ease;
  @apply shadow-shadow_5 relative rounded-xl;
}

.agentProfile_wrapper input[type="text"] {
  background-color: #fff;
  border: 1px solid #e6ecf4;
  box-shadow: 0px 0px 1px 0px #00000033;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 40px;
  width: 100%;
  padding: 0 0.875rem;
}

.formWrapperContent input[type="text"],
.formWrapperContent input[type="tel"],
.formWrapperContent input[type="email"],
.formWrapperContent input[type="date"],
.formWrapperContent input[type="number"] {
  background-color: #fff;
  border: 1px solid #e6ecf4;
  box-shadow: 0px 0px 1px 0px #00000033;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 40px;
  width: 100%;
  padding: 0 0.875rem;
}

.personalLoanCalc .modal-content {
  max-width: 600px;
}
