.dropdown {
  position: relative;
  width: 300px;
  border: 1px solid #e6ecf4;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.dropdown-header {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  border-radius: 8px;
  font-size: 0.875rem;
}

.dropdown-header svg {
  width: 35px;
  height: 20px;
  color: #00a068;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  z-index: 1;
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
}

.dropdown-option:hover {
  background: #f1f1f1;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-left: 8px;
}

.down {
  border-top: 5px solid #000; /* Down arrow */
}

.up {
  border-bottom: 5px solid #000; /* Up arrow */
}

/* Responsive css here */
@media (min-width: 768px) and (max-width: 991.98px) {
  .dropdown {
    width: 40% !important;
  }
  .loanTypeSection .dropdown {
    width: 100% !important;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .loanTypeSection .dropdown {
    width: 100% !important;
  }
}

@media (max-width: 575.98px) {
  .dropdown {
    width: 100%;
  }
  .loanTypeSection .dropdown {
    width: 100% !important;
  }
}
