.main-page-container {
  flex: 1;
  margin-top: 70px;
}

.navbar {
  background-color: #133b19;
  padding: 0.5rem 2rem;
  color: white;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.menu-icon {
  font-size: 2rem;
  cursor: pointer;
}

.navbar-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.3rem;
}

.content-section {
  background-color: #ffffff;
  margin: 10px;
}

.content-section h1 {
  margin-bottom: 1rem;
}

.content-section p {
  margin-bottom: 1rem;
}

/* Table.css */
.table-container {
  overflow-y: auto;
  height: calc(100vh - 100px);
}

h2 {
  padding: 10px !important;
}

.fixed-header-table {
  width: 100%;
  border-collapse: collapse;
}

.fixed-header-table thead {
  position: sticky;
  top: 0;
  background-color: #ddd;
  z-index: 1;
}

.fixed-header-table th,
.fixed-header-table td {
  border: 0.3px solid #ddd;
}

.fixed-header-table th {
  background-color: #3c8f47;
  color: #fff;
}

ul {
  list-style-type: none;
  padding: 10;
  margin: 10;
}

/* Mobile (Small Devices) */
@media (max-width: 768px) {
  .fixed-header-table th,
  .fixed-header-table td {
    font-size: 14px;
    padding: 8px;
  }

  .table-container {
    height: auto;
  }
}

/* Tablets and small laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .fixed-header-table th,
  .fixed-header-table td {
    font-size: 16px;
    padding: 10px;
  }
}

/* laptop and larger devices */
@media (min-width: 1025px) {
  .fixed-header-table th,
  .fixed-header-table td {
    padding: 12px;
  }
}

/* pop up  */
.popup {
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translatey(-50%);
  right: 0;
  z-index: 9;
}

.popup-inner-parent {
  background-color: #133b19;
  border-radius: 5px;
  width: 400px;
  z-index: 9;
  position: relative;
}

.popup-inner-parent h2 {
  color: #fff !important;
  padding-left: 20px;
}

.popup-inner {
  background-color: #ddd;
  padding-top: 10px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding-bottom: 20px !important;
}

.scrollable-content {
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

input,
textarea {
  width: 95%;
  padding: 8px !important;
  align-items: center;
  /* margin-bottom: 16px !important; */
  border: 1px solid #ccc;
  border-radius: 4px;
}

.loanFormWrapper button {
  /* background-color: #007bff; */
  color: white;
  padding: 10px 20px !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.loanFormWrapper button:hover {
  background-color: #0056b3;
}

.loanFormWrapper button.close-btn {
  background-color: #dc3545;
}

.loanFormWrapper button.close-btn:hover {
  background-color: #c82333;
}

.error {
  color: red;
  font-size: 10px;
  margin-top: 0;
  margin-left: 4px;
  /* position: absolute;
  left: 0;
  bottom: -38px;
  right: 0;
  line-height: 1.2;
  display: flex;
  align-items: start;
  justify-content: start;
  min-height: 35px;
  top: auto; */
}

.loanCategoryButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* For small mobile devices (up to 575px) */

@media (max-width: 575px) {
  .loanCategoryButton {
    display: flex;
    flex-direction: column;
  }

  .popup {
    margin-top: 20%;
  }

  input,
  textarea {
    width: 88%;
  }
}

/* For normal mobile devices (576px to 767px) */

@media (min-width: 576px) and (max-width: 767px) {
  .loanCategoryButton {
    display: flex;
    flex-direction: column;
  }

  .popup {
    margin-top: 15%;
  }

  input,
  textarea {
    width: 100%;
  }
}

/* For tablets (768px to 1023px) */
@media (min-width: 768px) and (max-width: 1023px) {
  .loanCategoryButton {
    display: flex;
    flex-direction: column;
  }

  .popup {
    margin-top: 10%;
  }

  input,
  textarea {
    width: 96%;
  }
}

/* For laptops (1024px to 1439px) */
@media (min-width: 1024px) and (max-width: 1439px) {
  .loanCategoryButton {
    display: flex;
    flex-direction: column;
  }

  .popup {
    margin-top: 10%;
  }

  input,
  textarea {
    width: 95%;
  }
}

/* For large laptops or desktops (1440px and above) */
@media (min-width: 1440px) {
  .loanCategoryButton {
    display: flex;
    flex-direction: column;
  }

  .popup {
    margin-top: 5%;
  }

  input,
  textarea {
    width: 95%;
  }
}
